import { useState, useCallback } from "react";
import { isValidEmail } from "../../utils/validations";

type DataToSubmit = {
  question: string;
  signature: string;
  email: string;
};

type ErrorsData = {
  question: string;
  signature: string;
  email: string;
};

export const usePollen = () => {
  const [data, setData] = useState({} as DataToSubmit);
  const [errors, setErrors] = useState({} as ErrorsData);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;

      const newData = {
        ...data,
        [name]: value,
      };
      const formErrors = { ...errors };
      if (name === "email") {
        formErrors.email = !value
          ? ""
          : isValidEmail(value)
          ? ""
          : "Please enter a valid email address.";
      } else {
        formErrors[name] = value ? "" : "This field is required";
      }

      setData(newData);
      setErrors(formErrors);
    },
    [data, errors]
  );

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    const formErrors = { ...errors };

    if (name === "email") {
      formErrors.email = !value
        ? ""
        : isValidEmail(value)
        ? ""
        : "Please enter a valid email address.";
    } else {
      formErrors[name] = value ? "" : "This field is required";
    }

    setErrors(formErrors);
  };

  return {
    handleChange,
    handleBlur,
    data,
    errors,
  };
};
