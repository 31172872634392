import React from "react";
import { IPollenConsultContainerHero } from "../../assets/Types";
import styles from "./PollenConsultHeroContainer.module.scss";

const PollenConsultHeroContainer: React.FC<IPollenConsultContainerHero> = ({
  pollenConsultHero,
}) => {
  const { pollenConsultHeadline, pollenConsultSubcopy } = pollenConsultHero;

  return (
    <div className={styles.pollenConsultHeroContainer}>
      <h1 className="garamond">{pollenConsultHeadline}</h1>
      <p className="garamond">{pollenConsultSubcopy}</p>
    </div>
  );
};

export default PollenConsultHeroContainer;
