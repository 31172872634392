import React from "react";

import PollenConsultHeroContainer from "../PollenConsultHeroContainer/PollenConsultHeroContainer";
import PollenConsultContainer from "../PollenConsultContainer/PollenConsultContainer";
import { IPollenConsultPageContainer } from "../../assets/Types";
import styles from "./PollenConsultPageContainer.module.scss";

const PollenConsultPageContainer: React.FC<IPollenConsultPageContainer> = ({
  template,
}) => {
  const { pollenConsult, pollenConsultHero } = template;
  return (
    <section className={styles.pollenConsultPage}>
      <PollenConsultHeroContainer pollenConsultHero={pollenConsultHero} />
      <PollenConsultContainer pollenConsult={pollenConsult} />
    </section>
  );
};

export default PollenConsultPageContainer;
