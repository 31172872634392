import React from "react";

const InfoFilled: React.FC<{
  className?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}> = ({
  className = "",
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="info-filled-icon"
    >
      <path
        d="M7.9987 1.33301C4.3187 1.33301 1.33203 4.31967 1.33203 7.99967C1.33203 11.6797 4.3187 14.6663 7.9987 14.6663C11.6787 14.6663 14.6654 11.6797 14.6654 7.99967C14.6654 4.31967 11.6787 1.33301 7.9987 1.33301ZM8.66536 11.333H7.33203V7.33301H8.66536V11.333ZM8.66536 5.99967H7.33203V4.66634H8.66536V5.99967Z"
        fill="#BBBBBB"
      ></path>
    </svg>
  );
};

export default InfoFilled;
