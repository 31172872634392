import React from "react";

import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoFilledIcon from "../../../components/SVGIcons/InfoFilled/InfoFilled";
import ErrorRoundedIcon from "../../../components/SVGIcons/ErrorRounded/ErrorRounded";
import { IPollenConsultInputField } from "../../../assets/Types";
import styles from "./PollenConsultFormField.module.scss";

const PollenConsultFormField: React.FC<IPollenConsultInputField> = ({
  field,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  const {
    addPollenConsultInputToolTip,
    isRequired,
    pollenConsultInputLabel,
    pollenConsultInputName,
    pollenConsultInputPlaceholder,
    pollenConsultInputToolTip,
    pollenConsultInputType,
  } = field;

  const isTextArea = pollenConsultInputType === "text-area";
  const inputClasses = error
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  const textAreaClasses = error
    ? `${styles.textArea} ${styles.textAreaError}`
    : styles.textArea;

  return (
    <div className={styles.row}>
      <label>
        {pollenConsultInputLabel}
        {addPollenConsultInputToolTip && (
          <Tooltip
            content={pollenConsultInputToolTip}
            className={styles.tooltip}
            contentClassName={styles.tooltipContent}
          >
            <InfoFilledIcon />
          </Tooltip>
        )}
      </label>
      <div
        id={pollenConsultInputName}
        className={styles.pollenConsultInputContainer}
      >
        {isTextArea ? (
          <>
            <textarea
              className={textAreaClasses}
              name={pollenConsultInputName}
              required={isRequired}
              placeholder={pollenConsultInputPlaceholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {error && (
              <p className={styles.pollenInputErrorMessage}>{helperText}</p>
            )}
          </>
        ) : (
          <>
            <input
              className={inputClasses}
              type={pollenConsultInputType}
              name={pollenConsultInputName}
              required={isRequired}
              placeholder={pollenConsultInputPlaceholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {error && (
              <>
                <ErrorRoundedIcon className={styles.inputErrorIcon} />
                <p className={styles.pollenInputErrorMessage}>{helperText}</p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PollenConsultFormField;
