import React from "react";
import { PageProps, graphql } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import { IPollenEthicsConsultLPTemplateData } from "../../assets/Types";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";
import PollenConsultPageContainer from "../../containers/PollenConsultPageContainer/PollenConsultPageContainer";

import "./styles.scss";
import { deIndexSeo } from "../../utils/deIndexSeo";

const PollenEthicsConsultLP: React.FC<
  PageProps<IPollenEthicsConsultLPTemplateData>
> = ({ data, location }) => {
  const { wpPage } = data;

  return (
    <DefaultPollenLayout noFooter noBanner pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <PollenConsultPageContainer template={wpPage.template} />
    </DefaultPollenLayout>
  );
};

export default PollenEthicsConsultLP;

export const POLLEN_ETHICS_CONSULTING_LP_QUERY = graphql`
  query PollenEthicsConsultPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        ... on WpPollenEthicsConsultLPTemplate {
          pollenConsult {
            pollenConsultCopyGraphic {
              altText
              mediaItemUrl
            }
            pollenConsultCopySectionBackground
            pollenConsultCopyText
            pollenConsultDisclamerText
            pollenConsultFormTitle
            pollenConsultInputs {
              addPollenConsultInputToolTip
              isRequired
              pollenConsultInputLabel
              pollenConsultInputName
              pollenConsultInputPlaceholder
              pollenConsultInputToolTip
              pollenConsultInputType
            }
          }
          pollenConsultHero {
            pollenConsultHeadline
            pollenConsultSubcopy
          }
        }
      }
    }
  }
`;
