import React from "react";

interface IErrorRounded {
  className?: string;
  width?: string;
  height?: string;
}
const ErrorRounded: React.FC<IErrorRounded> = ({
  className = "",
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="error-rounded-icon"
    >
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#FDEBE8"></rect>
      <path
        d="M11.95 13.2C11.29 13.2 10.75 12.66 10.75 12V7.2C10.75 6.54 11.29 6 11.95 6C12.61 6 13.15 6.54 13.15 7.2V12C13.15 12.66 12.61 13.2 11.95 13.2ZM13.15 18H10.75V15.6H13.15V18Z"
        fill="#EA3917"
      ></path>
      <path
        d="M11.95 13.2C11.29 13.2 10.75 12.66 10.75 12V7.2C10.75 6.54 11.29 6 11.95 6C12.61 6 13.15 6.54 13.15 7.2V12C13.15 12.66 12.61 13.2 11.95 13.2ZM13.15 18H10.75V15.6H13.15V18Z"
        fill="black"
        fillOpacity="0.1"
      ></path>
    </svg>
  );
};

export default ErrorRounded;
