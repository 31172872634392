import React from "react";

import PollenConsultFormField from "./PollenConsultFormField/PollenConsultFormField";
import RightArrow from "../../components/SVGIcons/RightArrow/RightArrow";
import { usePollen } from "./usePollen";
import { IPollenConsultFormContainer } from "../../assets/Types";
import styles from "./PollenConsultFormContainer.module.scss";

const PollenConsultFormContainer: React.FC<IPollenConsultFormContainer> = ({
  formTitle,
  inputs,
  disclaimerText,
}) => {
  const { data, handleChange, handleBlur, errors } = usePollen();

  return (
    <section className={styles.pollenConsultFormContainer}>
      <h2 className="garamond">{formTitle}</h2>

      <form
        acceptCharset="UTF-8"
        action="https://formkeep.com/f/f29573a88a44"
        method="POST"
        encType="multipart/form-data"
        id="pollen-consult-form"
        className={styles.pollenConsultPageForm}
      >
        <input className="garamond" name="utf8" type="hidden" value="✓" />
        {inputs.map((input, index) => (
          <PollenConsultFormField
            key={index}
            field={input}
            value={data[input.pollenConsultInputName]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors[input.pollenConsultInputName]}
            helperText={errors[input.pollenConsultInputName]}
          />
        ))}
        <div
          className={styles.disclaimer}
          dangerouslySetInnerHTML={{ __html: disclaimerText }}
        />
        <button type="submit" className={styles.pollenConsultSubmitBtn}>
          Submit a Question
          <RightArrow cls={styles.icon} />
        </button>
      </form>
    </section>
  );
};

export default PollenConsultFormContainer;
