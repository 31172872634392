import React from "react";

import PollenConsultFormContainer from "../PollenConsultFormContainer/PollenConsultFormContainer";
import { IPollenConsultContainer } from "../../assets/Types";
import styles from "./PollenConsultContainer.module.scss";

const PollenConsultContainer: React.FC<IPollenConsultContainer> = ({
  pollenConsult,
}) => {
  const {
    pollenConsultCopySectionBackground,
    pollenConsultCopyGraphic: { altText, mediaItemUrl },
    pollenConsultCopyText,
    pollenConsultDisclamerText,
    pollenConsultFormTitle,
    pollenConsultInputs,
  } = pollenConsult;

  return (
    <>
      <div
        className={styles.pollenConsultPageCopyBackground}
        style={{ backgroundColor: pollenConsultCopySectionBackground }}
      />
      <section className={styles.pollenConsultPageCopy}>
        <figure className={styles.copyGraphic}>
          <img src={mediaItemUrl} alt={altText} />
        </figure>

        <div
          className={`garamond ${styles.pollenContent}`}
          dangerouslySetInnerHTML={{ __html: pollenConsultCopyText }}
        />
      </section>
      <PollenConsultFormContainer
        formTitle={pollenConsultFormTitle}
        inputs={pollenConsultInputs}
        disclaimerText={pollenConsultDisclamerText}
      />
    </>
  );
};

export default PollenConsultContainer;
